import { Component } from "react"

class PledgeIndex extends Component {
  constructor(props) {
    super(props)
    this.state = props
  }
  componentDidMount() {
    if (typeof window !== `undefined`) window.location = "/pledge/register/"
  }
  render() {
    return null
  }
}

export default PledgeIndex
